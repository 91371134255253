<template>
  <lightgallery
        :settings="{ 
          speed: 500,
          showMaximizeIcon: false,
          download: false,
          toggleThumb: true,
          plugins: plugins 
        }"
        class="image-container"
        :onInit="onInit"
        :onBeforeSlide="onBeforeSlide"
    >
    <a 
      class="gallery-item"
      v-for="(img) in imgs" 
      :key="img.title" 
      :data-src="img.src" 
      :data-sub-html="img.html"
      :data-lg-size="img.size" >
      <img class="img-responsive" :title="img.title + ' - ' + img.author" :src="img.thumb" />
    </a>
  </lightgallery>
</template>

<script>

import Lightgallery from 'lightgallery/vue';
import lgZoom from 'lightgallery/plugins/zoom';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgAutoplay from 'lightgallery/plugins/autoplay';
import lgPlugin from 'lightgallery/plugins/rotate';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgHash from 'lightgallery/plugins/hash';


let lightGallery  = null;

export default {
  name: "ImageGrid",
  props: {
    imgs: Array,
  },
  components: {
      Lightgallery,
  },
  watch: {
    imgs() {
        this.$nextTick(() => {
            lightGallery.refresh();
        });
    },
  },
  data() {
    return {
      plugins: [lgZoom,lgThumbnail,lgPlugin,lgAutoplay,lgFullscreen,lgHash]
    }
  },
  created() {},
  computed: {},
  methods: {
    onInit: (detail) => {
      lightGallery = detail.instance;
    },
    onBeforeSlide: () => {
      console.log('calling before slide');
    },
  },
};
</script>
<style scoped>

@import '../../node_modules/lightgallery/css/lightgallery.css';
@import '../../node_modules/lightgallery/css/lg-zoom.css';
@import '../../node_modules/lightgallery/css/lg-thumbnail.css';
@import '../../node_modules/lightgallery/css/lg-rotate.css';
@import '../../node_modules/lightgallery/css/lg-autoplay.css';
@import '../../node_modules/lightgallery/css/lg-fullscreen.css';


.gallery-item {
  position: relative;
  padding-bottom: 100%;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.1);
}
.gallery-item:hover {
  top: -2px;
  box-shadow: 10px 20px 5px rgba(0, 0, 0, 0.2);
}
.gallery-item img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  left: 0;
  position: absolute;
  top: 0;
}
.gallery-item label{
    left: 42px;
    position: absolute;
    top: 125px;
}
</style>