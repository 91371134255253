<template>
  <image-grid :imgs="imgs" />
</template>

<script>
import moment from 'moment';
import ImageGrid from "./ImageGrid.vue";
import photos from "../../public/photos.json"

export default {
  name: "Index",
  components: {
    ImageGrid,
  },
  props: {},
  data() {
    return {
    };
  },
  created() {
    
  },
  computed: {
    imgs: function () {
      let imgs = [];
      photos.images.forEach(function(image, index){
        console.log(image)
        let html = "<h3>"+image.title+"</h3> <p>"+ image.author
        if(image.date){
          html += " 创作于 <a href='#'>"+  moment(image.date,'YYYY:MM:DD HH:mm:ss').format('YYYY-MM-DD') +" </a></p>"
        }else{
          html += " 创作 </p>"
        }
          imgs.push({
            title: image.title,
            author: image.author,
            src: image.src,
            thumb: image.thumb,
            size: image.size,
            html: html,
            index: index
          }) 
      })
      return imgs
    }
  }
};
</script>
<style scoped>
.image-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 15px;
}
</style>