<template>
  <!-- <h1>Mandy's Gallery</h1> -->
  <div class="logo">
    <img alt="Mandy's Gallery" src="./assets/logo.png">
  </div>
  <Index />
</template>

<script>
import Index from './components/Index.vue'

export default {
  name: 'App',
  components: {
    Index
  }
}
</script>

<style>
body {
  margin: 0em;
  padding: 0 1em 0 1em;
  font-family: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  -webkit-text-size-adjust: 100%;
  line-height: 1.4;
  /* color: #0e3481; */
  /* background-image: linear-gradient(#e8f0ff 0%, white 52.08%); */
  background-image: linear-gradient(#363535 0%, rgb(105, 102, 102) 52.08%);
  min-height: 100vh;
}
.logo{
  width: 100%;
  text-align: center;
}
</style>
